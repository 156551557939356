import { FC } from "react";
import { IconContext } from "react-icons/lib";
import styled from "styled-components";

const Wrapper = styled.li`
  display: flex;
  list-style: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1em;
  padding: 0;
  font-size: 0.9rem;

  @media (min-width: 321px) {
    font-size: 1rem;
  }
`;

type TileProps = {
  title: string;
  icon: JSX.Element;
};

const Tile: FC<TileProps> = ({ title, icon }) => (
  <Wrapper>
    <IconContext.Provider value={{ size: "1.5em", style: { verticalAlign: "middle" } }}>{icon}</IconContext.Provider>
    <div>{title}</div>
  </Wrapper>
);

export default Tile;
