import { FC, RefObject, useEffect, useState } from "react";
import { BsFillFileArrowUpFill } from "react-icons/bs";
import { IconContext } from "react-icons/lib";
import styled from "styled-components";

export const ButtonStyled = styled.div`
  position: absolute;
  right: 20px;
  bottom: 50px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #f7ded4;
`;

type Props = {
  containerRef: RefObject<HTMLDivElement | null>;
};

const ScrollToTop: FC<Props> = ({ containerRef }) => {
  const [visible, setVisible] = useState(false);

  const main = containerRef;

  const scrollToTop = () => {
    main.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (main.current) {
      main.current.addEventListener("scroll", () => {
        if (!main.current) return;
        if (main.current.scrollTop >= window.innerHeight) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      });
    }
  }, []);

  return (
    <ButtonStyled>
      <IconContext.Provider value={{ style: { display: visible ? "inline" : "none" } }}>
        <BsFillFileArrowUpFill onClick={scrollToTop} />
      </IconContext.Provider>
    </ButtonStyled>
  );
};
export default ScrollToTop;
