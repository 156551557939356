import { FC, useRef } from "react";
import styled from "styled-components";

import Header from "../../components/Header";
import About from "../../components/About";
import Stack from "../../components/Stack";
import Contact from "../../components/Contact";
import ScrollToTop from "../../components/ScrollToTop";

const Wrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
`;

const Home: FC = () => {
  const main = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={main}>
      <Header />
      <About />
      <Stack />
      <Contact />
      <ScrollToTop containerRef={main} />
    </Wrapper>
  );
};

export default Home;
