import { FC } from "react";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import Home from "./pages/Home/Home";

const GlobalStyle = createGlobalStyle`
  ${reset}

  body {
    font-family: Raleway, sans-serif;
    background-color: #222222;
    color: #f7ded4;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const App: FC = () => (
  <>
    <GlobalStyle />
    <Home />
  </>
);

export default App;
