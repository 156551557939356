import { FC } from "react";
import styled from "styled-components";
import { useMediaQuery } from "@react-hook/media-query";
import { DiGo, DiPostgresql, DiReact, DiRuby, DiRedis } from "react-icons/di";
import {
  SiAmazonaws,
  SiDocker,
  SiElixir,
  SiGit,
  SiGraphql,
  SiHeroku,
  SiJavascript,
  SiJest,
  SiKubernetes,
  SiRubyonrails,
  SiStyledcomponents,
  SiTailwindcss,
  SiTerraform,
  SiTestinglibrary,
  SiTypescript,
} from "react-icons/si";
import Tile from "./Tile";

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5em;
  background-color: #222222;
  color: #f7ded4;

  justify-content: center;
  row-gap: 25px;
  @media (min-width: 900px) {
    scroll-snap-align: start;
    height: 100vh;
  }
`;

const Header = styled.h3`
  width: 100%;
  text-align: center;
  font-family: Lobster;
  color: #e9967a;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 1em;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  @media (max-width: 900px) {
    justify-content: center;
    scroll-snap-align: start;
    height: 100vh;
    row-gap: 25px;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 15px;

  justify-content: center;
  @media (min-width: 321px) {
    row-gap: 25px;
  }
`;

const Stack: FC = () => {
  const matches = useMediaQuery("only screen and (min-width: 600px)");
  return (
    <Wrapper id="stack">
      <Group>
        <Header>Main stack</Header>
        {matches ? (
          <>
            <List>
              <Tile title="Ruby" icon={<DiRuby />} />
              <Tile title="Javascript" icon={<SiJavascript />} />
              <Tile title="Typescript" icon={<SiTypescript />} />
              <Tile title="GraphQL" icon={<SiGraphql />} />
              <Tile title="Elixir" icon={<SiElixir />} />
            </List>
            <List>
              <Tile title="Ruby on Rails" icon={<SiRubyonrails />} />
              <Tile title="PostgreSQL" icon={<DiPostgresql />} />
              <Tile title="Redis" icon={<DiRedis />} />
              <Tile title="Jest" icon={<SiJest />} />
              <Tile title="Heroku" icon={<SiHeroku />} />
              <Tile title="Git" icon={<SiGit />} />
            </List>
            <List>
              <Tile title="React.js" icon={<DiReact />} />
              <Tile title="React testing library" icon={<SiTestinglibrary />} />
              <Tile title="Styled-Components" icon={<SiStyledcomponents />} />
              <Tile title="Tailwind" icon={<SiTailwindcss />} />
              <Tile title="AWS" icon={<SiAmazonaws />} />
            </List>
          </>
        ) : (
          <List>
            <Tile title="Ruby" icon={<DiRuby />} />
            <Tile title="Javascript" icon={<SiJavascript />} />
            <Tile title="Typescript" icon={<SiTypescript />} />
            <Tile title="GraphQL" icon={<SiGraphql />} />
            <Tile title="Elixir" icon={<SiElixir />} />
            <Tile title="Ruby on Rails" icon={<SiRubyonrails />} />
            <Tile title="PostgreSQL" icon={<DiPostgresql />} />
            <Tile title="Redis" icon={<DiRedis />} />
            <Tile title="Jest" icon={<SiJest />} />
            <Tile title="Heroku" icon={<SiHeroku />} />
            <Tile title="Git" icon={<SiGit />} />
            <Tile title="React.js" icon={<DiReact />} />
            <Tile title="React testing library" icon={<SiTestinglibrary />} />
            <Tile title="Styled-Components" icon={<SiStyledcomponents />} />
            <Tile title="Tailwind" icon={<SiTailwindcss />} />
            <Tile title="AWS" icon={<SiAmazonaws />} />
          </List>
        )}
      </Group>
      <Group>
        <Header>Dabbled with and keen on improving with...</Header>
        <List>
          <Tile title="Terraform" icon={<SiTerraform />} />
          <Tile title="Docker" icon={<SiDocker />} />
          <Tile title="AWS" icon={<SiAmazonaws />} />
          <Tile title="Kubernetes" icon={<SiKubernetes />} />
          <Tile title="Go" icon={<DiGo />} />
        </List>
      </Group>
    </Wrapper>
  );
};

export default Stack;
