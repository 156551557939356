import { FC } from "react";
import styled from "styled-components";
import Nav from "./Nav";

const Wrapper = styled.div`
  scroll-snap-align: start;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  height: 100vh;
`;

const Headline = styled.h1`
  font-family: Lobster Two;
  font-size: 3.5em;
  text-align: center;
  font-weight: bold;
  color: #e9967a;
  margin-bottom: 25px;
`;

const SubHeadline = styled.h2`
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 25px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

const Header: FC = () => (
  <Wrapper id="header">
    <RightColumn>
      <Headline>Hello, I&apos;m Alice!</Headline>
      <SubHeadline>A software developer with a thirst for learning and self improvement.</SubHeadline>
      <Nav />
    </RightColumn>
  </Wrapper>
);

export default Header;
