import { FC } from "react";
import styled from "styled-components";

const Wrapper = styled.ul`
  list-style: none;
  color: #f7ded4;
  max-width: 300px;
  justify-items: center;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 10px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
  }
`;

const Link = styled.a`
  color: #f7ded4;
  &:hover {
    color: #e9967a;
  }
`;

const About = styled.li`
  grid-area: 1 / 1 / 2 / 2;
  @media (min-width: 600px) {
    grid-area: 1 / 1 / 2 / 2;
  }
`;

const Stack = styled.li`
  grid-area: 2 / 1 / 3 / 2;
  @media (min-width: 600px) {
    grid-area: 1 / 2 / 2 / 3;
  }
`;

const Contact = styled.li`
  grid-area: 3 / 1 / 4 / 2;
  @media (min-width: 600px) {
    grid-area: 1 / 3 / 2 / 4;
  }
`;

const Nav: FC = () => (
  <Wrapper>
    <About>
      <Link href="#about">About</Link>
    </About>
    <Stack>
      <Link href="#stack">Stack</Link>
    </Stack>
    <Contact>
      <Link href="#contact">Contact me</Link>
    </Contact>
  </Wrapper>
);

export default Nav;
