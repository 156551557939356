import { FC } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons/lib";
import { TiSocialGithubCircular, TiSocialLinkedinCircular, TiSocialAtCircular } from "react-icons/ti";

const Wrapper = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
`;

const Item = styled.a`
  display: flex;
  list-style: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #222222;
  &:hover {
    color: #222222;
  }
`;

const Socials: FC = () => (
  <Wrapper>
    <Item href="https://github.com/alice-letourneur" target="_blank">
      <IconContext.Provider value={{ size: "4em", color: "inherit" }}>
        <TiSocialGithubCircular />
      </IconContext.Provider>
    </Item>
    <Item href="https://www.linkedin.com/in/alice-letourneur/" target="_blank">
      <IconContext.Provider value={{ size: "4em", color: "inherit" }}>
        <TiSocialLinkedinCircular />
      </IconContext.Provider>
    </Item>
    <Item href="mailto:alice.letourneur@gmail.com" target="_blank">
      <IconContext.Provider value={{ size: "4em", color: "inherit" }}>
        <TiSocialAtCircular />
      </IconContext.Provider>
    </Item>
  </Wrapper>
);

export default Socials;
