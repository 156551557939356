import { FC } from "react";
import styled from "styled-components";
import Socials from "./Socials";

const Wrapper = styled.div`
  position: relative;
  scroll-snap-align: start;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 50px;
  background: linear-gradient(180deg, #222222 66%, #e9967a 66%);
  color: #222222;
  height: 100vh;
  flex-direction: column;
`;

const Headline = styled.h1`
  display: flex;
  align-items: flex-end;
  font-family: Lobster;
  font-size: 3.5em;
  color: #e9967a;
  margin: 0;
  height: 66%;
  box-sizing: border-box;
  padding: 50px 0;
`;

const Contact: FC = () => (
  <Wrapper id="contact">
    <Headline>Feel free to reach out</Headline>
    <Socials />
  </Wrapper>
);

export default Contact;
