import { FC } from "react";
import styled from "styled-components";
import aliceletourneur from "../images/aliceletourneur.png";

const Wrapper = styled.div`
  position: relative;
  scroll-snap-align: start;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 50px;
  color: #222222;
  height: 100vh;
  background: linear-gradient(135deg, #222222 25%, #e9967a 25%);
  flex-direction: column;
  row-gap: 2em;
  justify-content: space-evenly;

  font-size: 0.9rem;

  @media (min-width: 600px) {
    background: linear-gradient(135deg, #222222 30%, #e9967a 30%);

    font-size: 1rem;
  }
  @media (min-width: 900px) {
    column-gap: 2em;
    flex-direction: row;
    justify-content: space-around;
    background: linear-gradient(135deg, #222222 40%, #e9967a 40%, #e9967a 84%, #222222 84%);
  }
`;

const Highlight = styled.span`
  font-weight: bold;
  font-family: Lobster;
  font-size: 20px;
`;

const Photo = styled.img`
  border-radius: 55px 35px;
  border: 5px solid #222222;
  height: 100px;

  @media (min-width: 321px) {
    border-radius: 90px 35px;
    height: 180px;
  }
  @media (min-width: 600px) {
    border-radius: 130px 50px;
    height: 300px;
  }
  z-index: 1;
`;

const PhotoWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  line-height: 1.15;
  max-width: 450px;
`;

const About: FC = () => (
  <Wrapper id="about">
    <PhotoWrapper>
      <Photo alt="Picture of Alice Letourneur" src={aliceletourneur} />
    </PhotoWrapper>
    <TextWrapper>
      <p>I&apos;m a UK based software developer currently working remotely for Buyapowa building enterprise advocacy solutions.</p>
      <p>
        Experienced in many aspects of web development, with a main interest in <Highlight>Ruby</Highlight> for back-end as well as{" "}
        <Highlight>Javascript</Highlight> and <Highlight>Typescript</Highlight> for front-end.
      </p>
      <p>
        I enjoy driving new projects from design to production, refactoring code and implementing well-tested new features to create the
        best user experience.
      </p>
      <p>Keen user of TDD to stay focus and sleep at night! </p>
    </TextWrapper>
  </Wrapper>
);

export default About;
